<template>
  <div
    class="d-flex justify-content-center"
    style="width: 100%; min-height: 100vh; background-color: #f8f8ff"
  >
    <div
      style="
        position: fixed;
        height: 80px;
        width: 100%;
        background-color: rgb(248, 248, 255);
        z-index: 999;
      "
    >
      <div style="display: flex; justify-content: center">
        <div style="width: 1140px" class="contact-content">
          <div class="p-1">
            <b-link :to="{ path: '/' }" class="brand-logo">
              <img
                style="width: 100px !important; height: 50px !important"
                src="@/assets/images/logo/csm_logo.png"
                alt="logo"
              />
            </b-link>
          </div>
        </div>
      </div>
    </div>
    <!-- content -->
    <div v-if="!privacy"><Spinner /></div>
    <div v-else v-html="privacy"></div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { firebaseService } from "@/firebase/firebase";
import Spinner from "@core/components/Spinner";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { BButton, BCol, BImg, BLink, BRow } from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    Spinner,
  },
  data() {
    return {
      privacy: "",
    };
  },
  async mounted() {
    await firebaseService.initFetchAndActivate();
    this.privacy = firebaseService.getValue("terms").asString();
  },
  computed: {},
};
</script>

<style lang="scss">
.contact-bg {
  background-image: url("./contact-us.png");
  background-position: center;
  background-size: cover;
  height: 500px;
}

@media screen and (max-width: 480px) {
  .contact-us {
    margin-top: 55% !important;
  }
  .contact-mail {
    display: block;
    margin-left: 0px !important;
    margin-top: 4px;
  }
}

.content-container {
  max-width: 1140px;
}

@import "@core/scss/vue/pages/page-misc.scss";
</style>
